import React, { useContext, useEffect, useState } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { calculateButtonStateColor } from "../../../infecto-lms-webapp/functions/helpers/calculateButtonStateColor";
import { LMS_WEBAPP_CONFIG_IMPL } from "../../../infecto-lms-webapp-impl/config";
import {
	AllNodesSearchContext,
	AllNodesSearchParameters,
} from "../../../infecto-lms-webapp/context/search-context/AllNodesSearchContextProvider";
import styled from "styled-components";
import { SearchTagsFilters_Query } from "@generated/SearchTagsFilters_Query.graphql";
import { SearchTagsFilters_QueryFragment$key } from "@generated/SearchTagsFilters_QueryFragment.graphql";
import { CloseIcon } from "./SearchFiltersIcons";

const QUERY = graphql`
    query SearchTagsFilters_Query {
        ...SearchTagsFilters_QueryFragment
    }
`;

const QUERY_FRAGMENT = graphql`
    fragment SearchTagsFilters_QueryFragment on Query {
        Learn {
            Tags {
                edges {
                    node {
                        id
                        name
                        colorHex
                    }
                }
            }
        }
        Viewer {
            Auth {
                currentUser {
                    user {
                        extension(userExtensionKind: InfectopharmUserExtension){
                            ... on InfectopharmUserExtension {
                                fachrichtung
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const SearchTagsFilter = () => {
	const query = useLazyLoadQuery<SearchTagsFilters_Query>(QUERY, {});
	const fragment = useFragment<SearchTagsFilters_QueryFragment$key>(QUERY_FRAGMENT, query);
	const {allNodesFilters, setAllNodesFilters, clearFilters} = useContext(AllNodesSearchContext);
	const filteredTagsIds = [
		"Tag:Tag:a771a9c2-af79-4ae0-be66-084cab0bf814",
		"Tag:Tag:a8a56032-2651-4f3a-9e93-f8cb76286f8a",
		"Tag:Tag:35f81720-beae-479e-b062-1c9287aaa1db",
		"Tag:Tag:e544c23b-0884-40e3-bcaf-af967b440bf5",
		"Tag:Tag:dc14fc8e-f76b-41f5-aa2c-a440b82e846a",
		"Tag:Tag:d9175cd0-d221-4616-8e40-3e278852a832",
	];

	const tagsList = fragment.Learn.Tags.edges?.filter((tag: any) =>
		filteredTagsIds.includes(atob(tag?.node.id!)),
	);

	const toggleTag = (tagId: string) => {
		const prevState = allNodesFilters;

		const isSelected = prevState.tagIds?.includes(tagId);
		const newTagIds = isSelected
			? prevState.tagIds?.filter((id) => id !== tagId)
			: [...(prevState.tagIds || []), tagId];

		const updatedFilters: AllNodesSearchParameters = {
			...prevState,
			tagIds: newTagIds,
		};
		setAllNodesFilters(updatedFilters);
	};

	const [isDataReady, setIsDataReady] = useState(false);
	const [userFachrichtung, setUserFachrichtung] = useState<string | null | undefined>();

	useEffect(() => {
		if (userFachrichtung && fragment.Learn.Tags.edges) {
			setIsDataReady(true);
		}
	}, [userFachrichtung, fragment.Learn.Tags.edges]);

	useEffect(() => {
		setUserFachrichtung(fragment.Viewer.Auth.currentUser?.user.extension?.fachrichtung);
		if (isDataReady && fragment.Learn.Tags.edges && userFachrichtung) {
			const selectedTagIds = fragment.Learn.Tags.edges
				.map((n: any) => n!.node!)
				.filter((tag: any) => userFachrichtung.toLowerCase().includes(tag.name.toLowerCase()))
				.map((tag: any) => tag.id);
			if (selectedTagIds && selectedTagIds?.length > 0) {
				const updatedFilters: AllNodesSearchParameters = {
					...allNodesFilters,
					tagIds: selectedTagIds,
				};
				setAllNodesFilters(updatedFilters);
			}
		}
	}, [isDataReady]);

	return (
		<div>
			<div className="flex flex-column  align-items-center xl:align-items-start xl:flex-row">
				<p className="text-base ">Fachbereiche:</p>
				<div className="pb-5 lg:p-1 flex flex-wrap justify-content-center xl:justify-content-start">
					<div className="p-1 flex">
						<TagContainer
							backgroundColor={
								allNodesFilters.tagIds?.length == 0 && allNodesFilters.query == undefined
									? LMS_WEBAPP_CONFIG_IMPL.brandColor
									: "#fff"
							}
							textColor={
								allNodesFilters.tagIds?.length == 0 && allNodesFilters.query == undefined
									? "#fff"
									: "#000"
							}
							colorPress={calculateButtonStateColor().pressColor}
							borderColor={LMS_WEBAPP_CONFIG_IMPL.brandColor}
							onClick={() => {
								clearFilters();
							}}
						>
							<span className="text-xs">Alle</span>
						</TagContainer>
					</div>
					<div className="flex flex-row align-items-center justify-content-center">
						<Divider/>
					</div>
					{tagsList
						?.map((n: any) => n!.node!)
						.sort((a: any, b: any) => a.name.localeCompare(b.name))
						.map((tag: any) => (
							<div key={"tag-" + tag.id} className="flex p-1">
								<TagContainer
									backgroundColor={
										allNodesFilters.tagIds?.includes(tag.id)
											? LMS_WEBAPP_CONFIG_IMPL.brandColor
											: "#fff"
									}
									textColor={!allNodesFilters.tagIds?.includes(tag.id) ? "#000" : "#fff"}
									colorPress={calculateButtonStateColor().pressColor}
									borderColor={LMS_WEBAPP_CONFIG_IMPL.brandColor}
									onClick={() => toggleTag(tag.id)}
								>
									<div className="flex flex-row">
										<span className="text-xs">{tag.name}</span>
										{allNodesFilters.tagIds?.includes(tag.id) ? (
											<div className="ml-2">
												<CloseIcon/>
											</div>
										) : null}
									</div>
								</TagContainer>
							</div>
						))}
				</div>
			</div>
		</div>
	);
};

interface TagProps {
	backgroundColor: string;
	borderColor: string;
	colorPress: string;
	textColor: string;
}

const TagContainer = styled.div<TagProps>`
    border-radius: 24px;
    border: 1px solid ${(props) => props.borderColor};
    background-color: ${(props) => props.backgroundColor};
    color: ${(props) => props.textColor};
    display: flex;
    padding: 6px 12px;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    :hover {
        background-color: ${LMS_WEBAPP_CONFIG_IMPL.brandColor};
        color: white;
    }

    @media screen and (max-width: 768px) {
        padding: 4px 6px;
    };
`;
const dividerColor = "#cbcbcb";
const Divider = styled.hr`
    width: 1px;
    height: 16px;
    margin-right: 2px;
    margin-left: 2px;
    background: ${dividerColor};
`;
