import React, { useRef } from "react";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { ProfileLink_UserDataFragment$key } from "@generated/ProfileLink_UserDataFragment.graphql";
import { useTypedDispatch } from "../../redux/redux-store";
import { logout } from "../../redux/slices/auth.slice";
import { PROFILE_LINK_MENU_ITEMS } from "../../../infecto-lms-webapp-impl/impl-profile-menu-items";
import { removeAllHiddenUserMessage } from "../../redux/slices/hidden-user-messages.slice";

const USER_DATA = graphql`
	fragment ProfileLink_UserDataFragment on User {
		extension(userExtensionKind: InfectopharmUserExtension) {
			... on InfectopharmUserExtension {
				salutation
				title
				lastName
				firstName
				efnNumber
			}
		}
	}
`;

interface OwnProps {
	userDataFragmentRef: ProfileLink_UserDataFragment$key | null;
	className?: string;
}

export const extractName = (userData: any) => {
	let salutation = userData?.salutation || "";
	const firstName = userData?.firstName || "";

	switch (salutation) {
		case "Mr":
			salutation = "Herr";
			break;
		case "Mrs":
			salutation = "Frau";
			break;
		case "Unknown":
			salutation = "";
			break;
		case "Divers":
			salutation = "";
			break;
	}

	const title = userData?.title || "";
	const lastName = userData?.lastName || "";
	if (salutation === "Frau" || salutation === "Herr") {
		return [salutation, title, lastName].filter((c) => c.length > 0).join(" ");
	} else {
		return [title, firstName, lastName].filter((c) => c.length > 0).join(" ");
	}
};

export function ProfileLink({ className, userDataFragmentRef }: OwnProps) {
	const userData = useFragment<ProfileLink_UserDataFragment$key>(USER_DATA, userDataFragmentRef);

	const dispatch = useTypedDispatch();
	const menu = useRef<Menu>();

	return (
		<div className={className}>
			<Button
				className="p-button-text text-700 font-bold text-sm"
				label={extractName(userData?.extension) + " ▾"}
				icon="pi pi-user"
				onClick={(event) => menu.current!.toggle(event)}
			/>
			<Menu
				model={PROFILE_LINK_MENU_ITEMS(
					() => {
						dispatch(logout());
						dispatch(removeAllHiddenUserMessage());
					},
					"ProfileMenu",
					false,
				)}
				popup
				ref={menu as any}
			/>
		</div>
	);
}
