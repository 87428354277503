/**
 * @generated SignedSource<<c49e54204f70fb9f6f98d3b84e3fd3d1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Salutation = "Divers" | "Mr" | "Mrs" | "Unknown" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProfileLink_UserDataFragment$data = {
  readonly extension: {
    readonly efnNumber?: string | null;
    readonly firstName?: string | null;
    readonly lastName?: string | null;
    readonly salutation?: Salutation | null;
    readonly title?: string | null;
  } | null;
  readonly " $fragmentType": "ProfileLink_UserDataFragment";
};
export type ProfileLink_UserDataFragment$key = {
  readonly " $data"?: ProfileLink_UserDataFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileLink_UserDataFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileLink_UserDataFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "userExtensionKind",
          "value": "InfectopharmUserExtension"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "extension",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "salutation",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "efnNumber",
              "storageKey": null
            }
          ],
          "type": "InfectopharmUserExtension",
          "abstractKey": null
        }
      ],
      "storageKey": "extension(userExtensionKind:\"InfectopharmUserExtension\")"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "a8d5f5d3ef9626ac67f14cf5a70055a7";

export default node;
