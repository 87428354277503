import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { DashboardScreen_Query } from "@generated/DashboardScreen_Query.graphql";
import { usePageTitle } from "../../infecto-lms-webapp/hooks/usePageTitle";
import { UserStatusMessage } from "../../infecto-lms-webapp/components/UserStatusMessage.component";
import { UserStartedNotBlockedNodesComponent } from "../components/dashboard/UserStartedNotBlockedNodes.component";
import { CarouselsTabsComponent } from "../components/dashboard/CarouselsTabs.component";
import { WideContainerWithTopBar } from "../containers/WideContainerWithTopBar.container";
import { CarouselContainer } from "../containers/Carousel.container";
import { PageMainText } from "../components/PageTitleText";
import { PageDefaultContainer } from "../containers/PageDefaultContainer";
import { useSelector } from "react-redux";
import {
	addHiddenUserMessage,
	selectHiddenUserMessages,
} from "../../infecto-lms-webapp/redux/slices/hidden-user-messages.slice";
import { HideableMessage } from "../components/HideableMessage";
import { useTypedDispatch } from "../../infecto-lms-webapp/redux/redux-store";

const DASHBOARD_QUERY = graphql`
    query DashboardScreen_Query {
        Viewer {
            Auth {
                currentUser {
                    user {
                        extension(userExtensionKind: InfectopharmUserExtension) {
                            ... on InfectopharmUserExtension {
                                salutation
                                title
                                lastName
                                firstName
                                efnNumber
                                country
                            }
                        }
                        ...UserStatusMessage_UserFragment
                    }
                }
            }
        }
    }
`;

export function DashboardScreen() {
	usePageTitle("Dashboard");
	const dispatch = useTypedDispatch();
	const hiddenUserMessages = useSelector(selectHiddenUserMessages);

	const query = useLazyLoadQuery<DashboardScreen_Query>(DASHBOARD_QUERY, {});
	const extractSalutation = (userData: any) => {
		let salutation = userData?.salutation || "";

		switch (salutation) {
			case "Mr":
				salutation = "Herr";
				break;
			case "Mrs":
				salutation = "Frau";
				break;
			case "Unknown":
				salutation = "";
				break;
			case "Divers":
				salutation = "";
				break;
		}

		const title = userData?.title || "";
		const lastName = userData?.lastName || "";
		if (salutation === "Frau" || salutation === "Herr") {
			return [salutation, lastName].filter((c) => c.length > 0).join(" ");
		} else {
			return [title, lastName].filter((c) => c.length > 0).join(" ");
		}
	};

	return (
		<PageDefaultContainer collapsed={false} screenName="dashboard">
			<WideContainerWithTopBar>
				{query.Viewer.Auth.currentUser?.user.extension?.country == "Oesterreich" && (
					<div>
						{!hiddenUserMessages.includes("salutationAustria") ? (
							<HideableMessage
								onClick={() => dispatch(addHiddenUserMessage("salutationAustria"))}
								content={
									<div>
										Willkommen auf der Lernplattform von InfectoPharm Deutschland. Sie können alle
										Bereiche dieser Website nutzen. Wenn Sie zertifizierte Module abschließen, haben
										Sie nach Bestehen die Möglichkeit, sich ein Zertifikat herunterzuladen und
										dieses bei der Österreichischen Ärztekammer einzureichen. Eine automatische
										Meldung der Punkte findet nicht statt.
									</div>
								}
							/>
						) : null}
					</div>
				)}
				<UserStatusMessage userFragment={query.Viewer.Auth.currentUser?.user!}/>

				<PageMainText>
					Hallo {extractSalutation(query.Viewer.Auth.currentUser?.user.extension)}, was möchten Sie
					heute lernen?
				</PageMainText>

				<p className="text-base">Beenden Sie Ihre begonnen Module</p>
				<CarouselContainer>
					<UserStartedNotBlockedNodesComponent/>
					<CarouselsTabsComponent/>
				</CarouselContainer>
			</WideContainerWithTopBar>
		</PageDefaultContainer>
	);
}
