/**
 * @generated SignedSource<<5c28b43eafc9c269de3134ee4745564f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SearchTagsFilters_Query$variables = {};
export type SearchTagsFilters_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SearchTagsFilters_QueryFragment">;
};
export type SearchTagsFilters_Query = {
  response: SearchTagsFilters_Query$data;
  variables: SearchTagsFilters_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SearchTagsFilters_Query",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "SearchTagsFilters_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SearchTagsFilters_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LearnQueries",
        "kind": "LinkedField",
        "name": "Learn",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TagsConnection",
            "kind": "LinkedField",
            "name": "Tags",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TagsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Tag",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "colorHex",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthViewerSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CurrentUser",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "userExtensionKind",
                            "value": "InfectopharmUserExtension"
                          }
                        ],
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "extension",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "fachrichtung",
                                "storageKey": null
                              }
                            ],
                            "type": "InfectopharmUserExtension",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": "extension(userExtensionKind:\"InfectopharmUserExtension\")"
                      },
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7c6f676f674dce2d6e5938ab8216e891",
    "id": null,
    "metadata": {},
    "name": "SearchTagsFilters_Query",
    "operationKind": "query",
    "text": "query SearchTagsFilters_Query {\n  ...SearchTagsFilters_QueryFragment\n}\n\nfragment SearchTagsFilters_QueryFragment on Query {\n  Learn {\n    Tags {\n      edges {\n        node {\n          id\n          name\n          colorHex\n        }\n      }\n    }\n  }\n  Viewer {\n    Auth {\n      currentUser {\n        user {\n          extension(userExtensionKind: InfectopharmUserExtension) {\n            __typename\n            ... on InfectopharmUserExtension {\n              fachrichtung\n            }\n          }\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "eb54b88ba29719b3b0ed067ae34137cf";

export default node;
