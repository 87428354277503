/**
 * @generated SignedSource<<c05ca0b7a70ca73ddbd2968f64ff9b81>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InfectopharmCountry = "Deutschland" | "Oesterreich" | "Unknown" | "%future added value";
export type Salutation = "Divers" | "Mr" | "Mrs" | "Unknown" | "%future added value";
export type DashboardScreen_Query$variables = {};
export type DashboardScreen_Query$data = {
  readonly Viewer: {
    readonly Auth: {
      readonly currentUser: {
        readonly user: {
          readonly extension: {
            readonly country?: InfectopharmCountry | null;
            readonly efnNumber?: string | null;
            readonly firstName?: string | null;
            readonly lastName?: string | null;
            readonly salutation?: Salutation | null;
            readonly title?: string | null;
          } | null;
          readonly " $fragmentSpreads": FragmentRefs<"UserStatusMessage_UserFragment">;
        };
      } | null;
    };
  };
};
export type DashboardScreen_Query = {
  response: DashboardScreen_Query$data;
  variables: DashboardScreen_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "userExtensionKind",
    "value": "InfectopharmUserExtension"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "salutation",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "efnNumber",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "country",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthViewerSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CurrentUser",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": (v0/*: any*/),
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "extension",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v1/*: any*/),
                              (v2/*: any*/),
                              (v3/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/)
                            ],
                            "type": "InfectopharmUserExtension",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": "extension(userExtensionKind:\"InfectopharmUserExtension\")"
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "UserStatusMessage_UserFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DashboardScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthViewerSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CurrentUser",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": (v0/*: any*/),
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "extension",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v1/*: any*/),
                              (v2/*: any*/),
                              (v3/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "globalUserStatus",
                                "storageKey": null
                              }
                            ],
                            "type": "InfectopharmUserExtension",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": "extension(userExtensionKind:\"InfectopharmUserExtension\")"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2c1445ab676bfd530c25fda7e47bd056",
    "id": null,
    "metadata": {},
    "name": "DashboardScreen_Query",
    "operationKind": "query",
    "text": "query DashboardScreen_Query {\n  Viewer {\n    Auth {\n      currentUser {\n        user {\n          extension(userExtensionKind: InfectopharmUserExtension) {\n            __typename\n            ... on InfectopharmUserExtension {\n              salutation\n              title\n              lastName\n              firstName\n              efnNumber\n              country\n            }\n          }\n          ...UserStatusMessage_UserFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment UserStatusMessage_UserFragment on User {\n  extension(userExtensionKind: InfectopharmUserExtension) {\n    __typename\n    ... on InfectopharmUserExtension {\n      globalUserStatus\n      country\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "aba785ecbbe82cf208919db0e4235f66";

export default node;
