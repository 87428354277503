/**
 * @generated SignedSource<<b9d7d632c09b8bee927fc06ad1462519>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type InfectopharmCountry = "Deutschland" | "Oesterreich" | "Unknown" | "%future added value";
export type UserStatus = "active" | "activeEfnNeeded" | "changeInProgress" | "doccheck" | "efnNeeded" | "inactive" | "initialValidation" | "noProcess" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type UserStatusMessage_UserFragment$data = {
  readonly extension: {
    readonly country?: InfectopharmCountry | null;
    readonly globalUserStatus?: UserStatus;
  } | null;
  readonly " $fragmentType": "UserStatusMessage_UserFragment";
};
export type UserStatusMessage_UserFragment$key = {
  readonly " $data"?: UserStatusMessage_UserFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserStatusMessage_UserFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserStatusMessage_UserFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "userExtensionKind",
          "value": "InfectopharmUserExtension"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "extension",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "globalUserStatus",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "country",
              "storageKey": null
            }
          ],
          "type": "InfectopharmUserExtension",
          "abstractKey": null
        }
      ],
      "storageKey": "extension(userExtensionKind:\"InfectopharmUserExtension\")"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "ae9ea1b92d3f46518f5e1082167dea11";

export default node;
