/**
 * @generated SignedSource<<fe74621cf5cd4bf15157922a23efabd8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LearningAchievementsScreen_Query$variables = {};
export type LearningAchievementsScreen_Query$data = {
  readonly Viewer: {
    readonly Auth: {
      readonly currentUser: {
        readonly user: {
          readonly " $fragmentSpreads": FragmentRefs<"UserStatusMessage_UserFragment">;
        };
      } | null;
    };
  };
};
export type LearningAchievementsScreen_Query = {
  response: LearningAchievementsScreen_Query$data;
  variables: LearningAchievementsScreen_Query$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LearningAchievementsScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthViewerSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CurrentUser",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "UserStatusMessage_UserFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LearningAchievementsScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthViewerSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CurrentUser",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "userExtensionKind",
                            "value": "InfectopharmUserExtension"
                          }
                        ],
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "extension",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "globalUserStatus",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "country",
                                "storageKey": null
                              }
                            ],
                            "type": "InfectopharmUserExtension",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": "extension(userExtensionKind:\"InfectopharmUserExtension\")"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0ac025fa0dbb3d8bbb226a1a3995beac",
    "id": null,
    "metadata": {},
    "name": "LearningAchievementsScreen_Query",
    "operationKind": "query",
    "text": "query LearningAchievementsScreen_Query {\n  Viewer {\n    Auth {\n      currentUser {\n        user {\n          ...UserStatusMessage_UserFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment UserStatusMessage_UserFragment on User {\n  extension(userExtensionKind: InfectopharmUserExtension) {\n    __typename\n    ... on InfectopharmUserExtension {\n      globalUserStatus\n      country\n    }\n  }\n}\n"
  }
};

(node as any).hash = "f9d4b1a4cfb05487af12d2cd7ec579ad";

export default node;
